import React from "react"

const IndexPage = () => (
  <div>
    <p>
      <br />
      <br />
      • Google LLC, con domicilio en 1600 Amphitheatre Parkway, Mountain View,
      California, USA, y commercial registration number: 3582691;
      <br />
      • Huawei Software Technologies Co., Ltd., con domicilio en 101 Software
      Avenue Yuhuatai District Nanjing, 210012, China, y número de registro
      estatal: 17040376-32;
      <br />
      • Whatsapp Inc., con domicilio en 1601, Willow Road, Menlo Park,
      California 94025, USA, y número de identificación: 264763400.
      <br />
      • Microsoft Corporation con domicilio en One Microsoft Way, Redmond, WA,
      98052, Estados Unidos y company number 600413485.
      <br />
      • Twilio Inc., con domicilio en 375 Beale St #300, San Francisco, CA 94105
      <br />
      • Gupshup Inc., 415 Jackson Street, Suite B, San Francisco, CA 94111
      <br />
      • Infobip ltd, con domicilio en 5th Floor, 86 Jermyn Street, London SW1Y
      6AW
      <br />• Facebook Inc., con domicilio en 1 Hacker Way, Menlo Park, CA
      94025, United States
    </p>
  </div>
)

export default IndexPage
